import { combineReducers } from "redux";

import sidebarReducer from "./sidebarReducer";
import userReducer from "./userReducer";
import comboReducer from "./comboReducer";
import globalReducer from "./globalReducer";
import homeReducer from "./homeReducer";
import jenisBantuanReducer from "./jenisBantuanReducer";
import loginReducer from "./loginReducer";

import kjskbReducer from "./master/kjskbReducer";
import surveyorReducer from "./master/surveyorReducer";
import wilayahAdministrasiReducer from "./master/wilayahAdministrasiReducer";
import wilKabupatenReducer from "./master/wilKabupatenReducer";
import wilKecamatanReducer from "./master/wilKecamatanReducer";
import wilDesaReducer from "./master/wilDesaReducer";
import ubahProfileReducer from "./master/ubahProfileReducer";
import ubahPasswordReducer from "./master/ubahPasswordReducer";

import konfigurasiAplikasiReducer from "./pengaturan/konfigurasiAplikasiReducer";
import menuReducer from "./pengaturan/menuReducer";
import bannerReducer from "./pengaturan/bannerReducer";
import iklanReducer from "./pengaturan/iklanReducer";
import korwilDataSbReducer from "./korwil/korwilDataSbReducer";

import daftarReducer from "./daftarReducer";
import perpanjangLisensiSbReducer from "./perpanjang/perpanjangLisensiSbReducer";
import perpanjangMemberSbReducer from "./perpanjang/perpanjangMemberSbReducer";
import perpanjangMemberKjsbReducer from "./perpanjang/perpanjangMemberKjsbReducer";

import pindahWilkerSbReducer from "./pindahWilkerKjsb/pindahWilkerSbReducer";
import pindahKjsbSatuWilkerReducer from "./pindahWilkerKjsb/pindahKjsbSatuWilkerReducer";

///new refoldering
import permohonanReducer from "./pelayanan/permohonanReducer";
import cekPlotReducer from "./pelayanan/cekPlotReducer";
import hitungBiayaDanPenjadwalanReducer from "./pelayanan/hitungBiayaDanPenjadwalanReducer";
import pembayaranReducer from "./pelayanan/pembayaranReducer";
import pengukuranReducer from "./pelayanan/pengukuranReducer";
import pengolahanReducer from "./pelayanan/pengolahanReducer";
import legalisasiGuReducer from "./pelayanan/legalisasiGuReducer";
import hasilAkhirReducer from "./pelayanan/hasilAkhirReducer";

const reducer = combineReducers({
  sidebarReducer,
  userReducer,
  comboReducer,
  globalReducer,
  homeReducer,
  jenisBantuanReducer,
  loginReducer,

  kjskbReducer,
  surveyorReducer,
  wilayahAdministrasiReducer,
  wilKabupatenReducer,
  wilKecamatanReducer,
  wilDesaReducer,
  ubahProfileReducer,
  ubahPasswordReducer,

  konfigurasiAplikasiReducer,
  menuReducer,
  bannerReducer,
  iklanReducer,

  korwilDataSbReducer,
  daftarReducer,

  perpanjangLisensiSbReducer,

  pindahWilkerSbReducer,
  perpanjangMemberSbReducer,
  perpanjangMemberKjsbReducer,

  pindahKjsbSatuWilkerReducer,

  //new refoldering
  permohonanReducer,
  cekPlotReducer,
  hitungBiayaDanPenjadwalanReducer,
  pembayaranReducer,
  pengukuranReducer,
  pengolahanReducer,
  legalisasiGuReducer,
  hasilAkhirReducer,
});

export default reducer;
