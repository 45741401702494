import { FUNCDateToString, http } from 'src/config';
import { URLSVC, URL } from 'src/config/helper/urlService';
import Swal from 'sweetalert2'
import { loadingBar, setImgPreview } from '../globalAction';

export const setDataBanners = () => {
    loadingBar(true);
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetBanners`).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                dispatch({type: 'DATA_BANNERS', payload: data.Data})
            } else {
                if(data.ReturnMessage != "data tidak ada") 
                    Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}


export const setDataBanner = (idBanner) => {
    return (dispatch) => {
        loadingBar(true);
        dispatch({type: 'RESET_FORM_BANNER'})
        http.get(`${URLSVC}/Webs/GetBanner?IdBanner=${idBanner}`).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                dispatch({ type: 'DATA_BANNER', payload: data.Data })

                dispatch(setFormBanner('IdMobileBanner', data.Data.IdMobileBanner))
                dispatch(setFormBanner('Description', data.Data.Description))
                dispatch(setFormBanner('MinTanggalTayang', new Date(data.Data.MinTanggalTayang)))
                dispatch(setFormBanner('MaxTanggalTayang', new Date(data.Data.MaxTanggalTayang)))
                dispatch(setFormBanner('ImageBanner', data.Data.ImageBanner))

                const SELECT = {
                    Status: {
                        value: data.Data.Status.Id,
                        label: data.Data.Status.Nama
                    }
                };
                dispatch(setFormBanner('Status', SELECT.Status))

                dispatch(setImgPreview('FILE_IMAGE_BANNER_PREVIEW', `${data.Data.ImageBanner}?${new Date().getTime()}`));

                dispatch({type: 'MODAL_BANNER', payload: true})
                
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}

export const setFormBanner = (formType, formValue) => {
    return {type: 'FORM_BANNER', formType, formValue}
}


export const apiAddBanner = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('Description', iData.Description);
        fd.append('MinTanggalTayang', FUNCDateToString(iData.MinTanggalTayang));
        fd.append('MaxTanggalTayang', FUNCDateToString(iData.MaxTanggalTayang));
        fd.append('Status', iData.Status.value);
        fd.append('FileUpload', iData.FileUpload);

        http.post(`${URLSVC}/Webs/AddBanner`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                Swal.fire('Berhasil', `Anda telah berhasil menambah data`, 'success').then(function() {
                    dispatch({ type: 'MODAL_BANNER', payload: false })
                    dispatch(setDataBanners())
                });
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}

export const apiUpdateBanner = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('IdMobileBanner', iData.IdMobileBanner);
        fd.append('Description', iData.Description);
        fd.append('MinTanggalTayang', FUNCDateToString(iData.MinTanggalTayang));
        fd.append('MaxTanggalTayang', FUNCDateToString(iData.MaxTanggalTayang));
        fd.append('Status', iData.Status.value);
        fd.append('FileUpload', iData.FileUpload);


        http.post(`${URLSVC}/Webs/UpdateBanner`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                Swal.fire('Berhasil', `Anda telah berhasil memperbarui data`, 'success').then(function() {
                    dispatch({ type: 'MODAL_BANNER', payload: false })
                    dispatch(setDataBanners())
                });
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}


export const apiDeleteBanner = (idBanner) => {
    return (dispatch) => {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: `Anda akan hapus banner ini!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, saya yakin!'
        }).then((result) => {
            if (result.isConfirmed) {
                loadingBar(true);
                http.get(`${URLSVC}/Webs/DeleteBanner?IdBanner=${idBanner}`).then((res) => {
                    loadingBar(false);
                    let data = res.data;
                    if (data.IsSuccess) {
                        Swal.fire('Berhasil', `Anda berhasil hapus banner`, 'success').then(function() {
                            dispatch(setDataBanners());
                        });
                    } else {
                        Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
                    }
                })
            }
        })
    }
}