import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import {
  FUNCArraySelectKode,
  FUNCArraySelectId,
  FUNCArraySelectRole,
  http,
} from "src/config";
import axios from "axios";

export const setDataComboProv = (type) => {
  return (dispatch) => {
    axios.get(`${URLSVC}/Webs/GetProvinsis`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectKode(data.Data);
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataComboKab = (type, KdProv) => {
  return (dispatch) => {
    http
      .get(`${URLSVC}/Pelayanans/GetKabupatens?IdWilkerKjsb=${KdProv}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          let array = FUNCArraySelectKode(data.Data);
          dispatch({ type, payload: array });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataComboKec = (type, KdKab) => {
  return (dispatch) => {
    http
      .get(`${URLSVC}/Pelayanans/GetKecamatans?IdKabupaten=${KdKab}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          let array = FUNCArraySelectKode(data.Data);
          dispatch({ type, payload: array });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataComboDesa = (type, KdKec) => {
  return (dispatch) => {
    http
      .get(`${URLSVC}/Pelayanans/GetDesas?IdKecamatan=${KdKec}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          let array = FUNCArraySelectKode(data.Data);
          dispatch({ type, payload: array });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataComboJenisSurveyors = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetJenisSurveyors`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataComboRole = (type = "DATA_COMBO_ROLE") => {
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetRoles`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectRole(data.Data);
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataComboKelPermohonan = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetKelPermononans`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataComboJenisPermohonan = (type, idKelPermohonan) => {
  return (dispatch) => {
    http
      .get(
        `${URLSVC}/Pelayanans/GetJenisPermohonans?IdKelPermohonan=${idKelPermohonan}`
      )
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          let array = FUNCArraySelectId(data.Data);
          dispatch({ type, payload: array });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataComboSyaratPermohonan = (type, idJenisPermohonan) => {
  return (dispatch) => {
    axios
      .get(
        `${URLSVC}/Pelayanans/GetSyaratPermohonans?IdJenisPermohonan=${idJenisPermohonan}`
      )
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          let array = FUNCArraySelectId(data.Data);
          dispatch({ type, payload: array });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataComboBiayaJenisProperties = (type) => {
  return (dispatch) => {
    axios.get(`${URLSVC}/Pelayanans/GetBiayaJenisProperties`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        let array = FUNCArraySelectId(data.Data);
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataComboKjsb = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetKjsbs`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        // let array = FUNCArraySelectId(data.Data)
        var array = [];
        array.push({
          value: "",
          label: "SELECT ONE",
        });
        data.Data.map((v, i) => {
          array.push({
            value: v.IdKjsb,
            label: `${v.Nama}  (${v.WilKer})`,
          });
        });
        dispatch({ type, payload: array });
      } else {
        // Swal.fire('Gagal', `${data.ReturnMessage}`, 'error')
      }
    });
  };
};

export const setDataComboKjsbByProv = (type, idProv) => {
  return (dispatch) => {
    axios
      .get(`${URLSVC}/Webs/GetKjsbsByProvinsi?IdProvinsi=${idProv}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          // let array = FUNCArraySelectId(data.Data)
          var array = [];
          array.push({
            value: "",
            label: "SELECT ONE",
          });
          data.Data.map((v, i) => {
            array.push({
              value: v.IdKjsb,
              label: `${v.Nama}  (${v.WilKer})`,
            });
          });
          dispatch({ type, payload: array });
        } else {
          Swal.fire("Gagal", `kjsb ${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataComboKjsbByWilker = (type) => {
  return (dispatch) => {
    http.get(`${URLSVC}/Webs/GetKjsbsByWilker`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        var array = [];
        array.push({
          value: "",
          label: "SELECT ONE",
        });
        data.Data.map((v, i) => {
          array.push({
            value: v.IdKjsb,
            label: `${v.Nama}  (${v.WilKer})`,
          });
        });
        dispatch({ type, payload: array });
      } else {
        Swal.fire("Gagal", `kjsb ${data.ReturnMessage}`, "error");
      }
    });
  };
};
