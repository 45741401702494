import axios from "axios";
import { useHistory } from "react-router-dom";
import { http } from "src/config";
import { FUNCArraySelectId, FUNCNumberFormat } from "src/config/function";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import {
  setDataComboDesa,
  setDataComboKab,
  setDataComboKec,
  setDataComboProv,
} from "../comboAction";
import { loadingBar, setDataPermohonans } from "../globalAction";
import {
  setDataBiayaJadwalSurveyors,
  setFormBiayaJadwalDataPermohonan,
} from "./hitungBiayaDanPenjadwalanAction";

export const setDataPermohonan = (idPermohonan) => {
  loadingBar(true);
  return (dispatch) => {
    dispatch({ type: "RESET_FORM_PERMOHONAN" });
    dispatch({ type: "FILE_SYARAT_PERMOHONAN", payload: [] });
    http
      .get(
        `${URLSVC}/Pelayanans/GetPermohonanById?IdPermohonan=${idPermohonan}`
      )
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch(setFormPermohonan("IdPermohonan", data.Data.IdPermohonan));
          dispatch(setFormPermohonan("NoPermohonan", data.Data.NoPermohonan));
          dispatch(setFormPermohonan("Nama", data.Data.Nama));
          dispatch(setFormPermohonan("NoKtp", data.Data.NoKtp));
          dispatch(setFormPermohonan("Alamat", data.Data.Alamat));
          dispatch(setFormPermohonan("NoHp", data.Data.NoHp));
          dispatch(setFormPermohonan("Email", data.Data.Email));
          dispatch(
            setFormPermohonan("AlamatBidangTanah", data.Data.AlamatBidangTanah)
          );
          dispatch(setFormPermohonan("LuasTanah", data.Data.LuasTanah));
          dispatch(
            setFormPermohonan(
              "JarakLokasiBidangTanah",
              data.Data.JarakLokasiBidangTanah
            )
          );
          dispatch(
            setFormPermohonan(
              "JumlahBidangSebelum",
              data.Data.JumlahBidangSebelum || ""
            )
          );
          dispatch(
            setFormPermohonan(
              "JumlahBidangSetelah",
              data.Data.JumlahBidangSetelah || ""
            )
          );
          dispatch(setFormPermohonan("Latitude", data.Data.Latitude || ""));
          dispatch(setFormPermohonan("Longitude", data.Data.Longitude || ""));
          if (data.Data.JadwalUkur !== "")
            dispatch(
              setFormBiayaJadwalDataPermohonan(
                "JadwalUkur",
                new Date(data.Data.JadwalUkur)
              )
            );

          const SELECT = {
            IdJenisPermohonan: {
              value: data.Data.JenisPermohonan.Id,
              label: data.Data.JenisPermohonan.Nama,
            },
            IdKjsb: {
              value: data.Data.Kjsb.Id,
              label: data.Data.Kjsb.Nama,
            },
            IdSurveyor: {
              value: data.Data.Surveyor?.Id,
              label: data.Data.Surveyor?.Nama,
            },
            IdWilker: {
              value: data.Data.Wilker.Id,
              label: data.Data.Wilker.Nama,
            },
            IdKabKot: {
              value: data.Data.Kabupaten.Id,
              label: data.Data.Kabupaten.Nama,
            },
            IdKec: {
              value: data.Data.Kecamatan.Id,
              label: data.Data.Kecamatan.Nama,
            },
            IdDesa: {
              value: data.Data.Desa.Id,
              label: data.Data.Desa.Nama,
            },
            IsDiwakilkan: {
              value: data.Data.IsDiwakilkan,
              label: data.Data.IsDiwakilkan ? "YA" : "TIDAK",
            },
          };

          dispatch(setFormPermohonan("NamaKjskb", SELECT.IdKjsb.label));
          dispatch(
            setFormPermohonan("IdJenisPermohonan", SELECT.IdJenisPermohonan)
          );
          dispatch(setFormPermohonan("IdKjsb", SELECT.IdKjsb));
          dispatch(setFormPermohonan("IdSurveyor", SELECT.IdSurveyor));
          dispatch(setFormPermohonan("IdWilker", SELECT.IdWilker));
          dispatch(setFormPermohonan("IsDiwakilkan", SELECT.IsDiwakilkan));

          dispatch(setDataComboProv("DATA_COMBO_PROV"));
          dispatch(setDataComboKab("DATA_COMBO_KAB", SELECT.IdWilker.value));
          dispatch(setDataComboKec("DATA_COMBO_KEC", SELECT.IdKabKot.value));
          dispatch(setDataComboDesa("DATA_COMBO_DESA", SELECT.IdKec.value));

          dispatch(setFormPermohonan("IdProv", SELECT.IdWilker));
          dispatch(setFormPermohonan("IdKabKot", SELECT.IdKabKot));
          dispatch(setFormPermohonan("IdKec", SELECT.IdKec));
          dispatch(setFormPermohonan("IdDesa", SELECT.IdDesa));
          setTimeout(() => {}, 2000);

          dispatch(
            setFileSyaratPermohonan(
              "FILE_SYARAT_PERMOHONAN",
              data.Data.JenisPermohonan.Id
            )
          );
          if (data.Data.JenisPermohonan.Kode === "pecahgabung") {
            dispatch(setFormPermohonan("IsInputJumlahBidang", true));
          } else {
            dispatch(setFormPermohonan("IsInputJumlahBidang", false));
          }

          dispatch({
            type: "DATA_PELAYANAN_PREVIEW_BERKAS",
            payload: data.Data,
          });
          dispatch({ type: "BERKAS_PERMOHONAN", payload: data.Data.Files });
          dispatch({
            type: "STATUS_BERKAS_PERMOHONAN",
            payload: data.Data.Statuss,
          });

          //hitung biaya
          dispatch(
            setDataBiayaJadwalSurveyors(
              "DATA_BIAYA_JADWAL_SURVEYORS",
              data.Data.Kjsb.Id
            )
          );
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setFormPermohonan = (formType, formValue) => {
  return { type: "FORM_PERMOHONAN", formType, formValue };
};

export const setFileSyaratPermohonan = (type, idJenisPermohonan) => {
  return (dispatch) => {
    axios
      .get(
        `${URLSVC}/Pelayanans/GetSyaratPermohonans?IdJenisPermohonan=${idJenisPermohonan}`
      )
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type, payload: data.Data });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataJenisPermohonan = (type, idKelPermohonan) => {
  return (dispatch) => {
    axios
      .get(
        `${URLSVC}/Pelayanans/GetJenisPermohonans?IdKelPermohonan=${idKelPermohonan}`
      )
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          var array = [];
          data.Data.map((v, i) => {
            array.push({
              value: v.Id,
              label: v.Nama,
              kode: v.Kode,
            });
          });
          dispatch({ type, payload: array });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};
export const setBiayaCheckPlot = (type) => {
  return (dispatch) => {
    axios.get(`${URLSVC}/Pelayanans/GetBiayaCheckPlot`).then((res) => {
      let data = res.data;
      if (data.IsSuccess) {
        dispatch(setFormPermohonan("RpChekplot", data.Data));
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataCekJenisPermohonan = (idJenisPermohonan) => {
  return (dispatch) => {
    http
      .get(
        `${URLSVC}/Pelayanans/GetJenisPermohonan?IdJenisPermohonan=${idJenisPermohonan}`
      )
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "warning").then(
            function () {
              window.location.reload();
            }
          );
        }
      });
  };
};

export const apiAddPermohonan = (iData, isGuest = false, history = null) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IsDiwakilkan", iData.IsDiwakilkan.value);
    fd.append("IdJenisPermohonan", iData.IdJenisPermohonan.value);
    fd.append("IdKjsb", iData.IdKjsb.value);
    fd.append("IdDesa", iData.IdDesa.value);
    fd.append("Nama", iData.Nama.toUpperCase());
    fd.append("NoKtp", iData.NoKtp);
    fd.append("Alamat", iData.Alamat);
    fd.append("NoHp", iData.NoHp);
    fd.append("Email", iData.Email);
    fd.append("AlamatBidangTanah", iData.AlamatBidangTanah);
    fd.append("LuasTanah", iData.LuasTanah);
    fd.append("JarakLokasiBidangTanah", iData.JarakLokasiBidangTanah);
    fd.append("Latitude", iData.Latitude);
    fd.append("Longitude", iData.Longitude);
    fd.append("JumlahBidangSebelum", iData.JumlahBidangSebelum || "");
    fd.append("JumlahBidangSetelah", iData.JumlahBidangSetelah || "");

    if (iData.Files !== "") {
      var i = 0;
      iData.Files.map((v, ix) => {
        if (typeof v.IdFile !== "undefined") {
          console.log(i);
          fd.append(`Files[${i}].IdFile`, v.IdFile);
          fd.append(`Files[${i}].FileUpload`, v.FileUpload);
          i++;
        }
      });
    }

    if (isGuest) {
      axios
        .post(`${URLSVC}/Pelayanans/AddPermohonanFromWeb`, fd)
        .then((res) => {
          loadingBar(false);
          let data = res.data;
          if (data.IsSuccess) {
            Swal.fire(
              "Berhasil",
              `Anda telah berhasil melakukan input data permohonan`,
              "success"
            ).then(function () {
              window.location.reload();
            });
          } else {
            Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
          }
        });
    } else {
      http.post(`${URLSVC}/Pelayanans/AddPermohonanFromWeb`, fd).then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          Swal.fire(
            "Berhasil",
            `Anda telah berhasil menambah data`,
            "success"
          ).then(function () {
            history.goBack();
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
    }
  };
};

export const apiEditPermohonan = (iData, history) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IsDiwakilkan", iData.IsDiwakilkan.value);
    fd.append("IdPermohonan", iData.IdPermohonan);
    fd.append("IdJenisPermohonan", iData.IdJenisPermohonan.value);
    fd.append("IdKjsb", iData.IdKjsb.value);
    fd.append("IdDesa", iData.IdDesa.value);
    fd.append("Nama", iData.Nama.toUpperCase());
    fd.append("NoKtp", iData.NoKtp);
    fd.append("Alamat", iData.Alamat);
    fd.append("NoHp", iData.NoHp);
    fd.append("Email", iData.Email);
    fd.append("AlamatBidangTanah", iData.AlamatBidangTanah);
    fd.append("LuasTanah", iData.LuasTanah);
    fd.append("JarakLokasiBidangTanah", iData.JarakLokasiBidangTanah);
    fd.append("Latitude", iData.Latitude || "");
    fd.append("Longitude", iData.Longitude || "");
    fd.append("JumlahBidangSebelum", iData.JumlahBidangSebelum || "");
    fd.append("JumlahBidangSetelah", iData.JumlahBidangSetelah || "");

    if (iData.Files !== "") {
      iData.Files.map((v, i) => {
        if (typeof v.IdFile !== "undefined") {
          fd.append(`Files[${i}].IdFile`, v.IdFile);
          fd.append(`Files[${i}].FileUpload`, v.FileUpload);
        }
      });
    }

    http.post(`${URLSVC}/Pelayanans/EditPermohonanFromWeb`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Anda telah berhasil memperbarui data`,
          "success"
        ).then(function () {
          // dispatch({ type: "MODAL_PERMOHONAN", payload: false });
          // dispatch(setDataPermohonans());
          history.goBack();
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const apiVerifikasiBerkasPermohonan = (
  idPermohonan,
  rpChekplot,
  history
) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdPermohonan", idPermohonan);
    fd.append("StatusProses", 1);
    fd.append("RpChekplot", FUNCNumberFormat(rpChekplot));

    http.post(`${URLSVC}/Pelayanans/VerifikasiBerkas`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Anda telah berhasil validasi berkas pemohon`,
          "success"
        ).then(function () {
          history.goBack();
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const apiVerifikasiTolakBerkasPermohonan = (
  idPermohonan,
  catatan,
  history
) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    if (!catatan) {
      Swal.fire("Gagal", `Catatan harus diisi!`, "error");
      return;
    }

    fd.append("IdPermohonan", idPermohonan);
    fd.append("Catatan", catatan);
    fd.append("StatusProses", -1);

    http.post(`${URLSVC}/Pelayanans/VerifikasiBerkas`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Anda telah berhasil menolak berkas pemohon`,
          "success"
        ).then(function () {
          history.goBack();
          dispatch({ type: "MODAL_PERMOHONAN_VALIDASI", payload: false });
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const apiDeletePermohonanFromWeb = (idPermohonan, nama) => {
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan hapus pemohon (${nama})!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        http
          .post(
            `${URLSVC}/Pelayanans/DeletePermohonanFromWeb?idPermohonan=${idPermohonan}`
          )
          .then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda berhasil hapus pemohon (${nama})`,
                "success"
              ).then(function () {
                dispatch(setDataPermohonans("DATA_PERMOHONANS", 1));
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};
