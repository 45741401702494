import { FUNCDateToString, http } from "src/config";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "src/config/redux/action";

export const setFormPerubahanWilkerSurveyor = (formType, formValue) => {
  return { type: "FORM_PERUBAHAN_WILKER_SURVEYOR", formType, formValue };
};

export const setDataPerubahanWilkerSurveyors = () => {
  return (dispatch) => {
    loadingBar(true);
    http.get(`${URLSVC}/Webs/GetPerubahanWilkerSurveyors`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({
          type: "DATA_PERUBAHAN_WILKER_SURVEYORS",
          payload: data.Data,
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataPerubahanWilkerSurveyor = (id, setValue = null) => {
  return (dispatch) => {
    loadingBar(true);
    http
      .get(`${URLSVC}/Webs/GetPerubahanWilkerSurveyor?IdMemberUpdate=${id}`)
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch(setFormPerubahanWilkerSurveyor("IdMemberUpdate", id));
          dispatch(setFormPerubahanWilkerSurveyor("Nama", data.Data.Nama));
          dispatch(
            setFormPerubahanWilkerSurveyor("NoLisensi", data.Data.NoLisensi)
          );
          dispatch(
            setFormPerubahanWilkerSurveyor("WilkerFrom", data.Data.WilkerFrom)
          );
          dispatch(
            setFormPerubahanWilkerSurveyor("WilkerTo", data.Data.WilkerTo.Nama)
          );
          dispatch(
            setFormPerubahanWilkerSurveyor(
              "TanggalPengajuan",
              data.Data.TanggalPengajuan
            )
          );
          dispatch(
            setFormPerubahanWilkerSurveyor(
              "TanggalRespon",
              data.Data.TanggalRespon
            )
          );
          dispatch(
            setFormPerubahanWilkerSurveyor("Catatan", data.Data.Catatan)
          );
          dispatch(setFormPerubahanWilkerSurveyor("Status", data.Data.Status));
          dispatch(
            setFormPerubahanWilkerSurveyor("FileDownloads", data.Data.Files)
          );
          dispatch({
            type: "DATA_PERUBAHAN_WILKER_SURVEYOR",
            payload: data.Data,
          });
          dispatch({
            type: "MODAL_PERUBAHAN_WILKER_SURVEYOR",
            payload: true,
          });
          if (setValue !== null) {
            setValue("IdMemberUpdate", id);
            setValue("Nama", data.Data.Nama);
          }
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiPerubahanWilkerSurveyor = (iData, reset) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("IdWilker", iData.IdWilker?.value);
    if (iData.Files.length !== 0) {
      iData.Files.map((v, i) => {
        if (typeof v.IdFile !== "undefined") {
          fd.append(`FileBerkas[${i}].IdFile`, v.IdFile);
          fd.append(`FileBerkas[${i}].FileUpload`, v.FileUpload);
        }
      });
    } else {
      loadingBar(false);
      Swal.fire("Gagal", `File berkas wajib di upload`, "error");
      return;
    }

    http.post(`${URLSVC}/Webs/PerubahanWilkerSurveyor`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Pengajuan untuk pindah wilayah kerja berhasil dilakukan, silahkan tunggu konfirmasi dari operator!`,
          "success"
        ).then(function () {
          dispatch(setDataPerubahanWilkerSurveyors());
        });

        //reset value
        reset({ IdWilker: "" });
        iData.Files.map((v, i) => {
          var idFile = document.getElementById(`file${i}`);
          idFile.value = idFile.defaultValue;
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const apiBatalPerubahanWilkerSurveyor = (id) => {
  return (dispatch) => {
    loadingBar(true);
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan membatalkannya!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        http
          .get(
            `${URLSVC}/Webs/BatalPerubahanWilkerSurveyor?IdMemberUpdate=${id}`
          )
          .then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda telah membatalkan pindah wilayah kerja`,
                "success"
              ).then(function () {
                dispatch(setDataPerubahanWilkerSurveyors());
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};

export const apiApprovePerubahanWilkerSurveyor = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan pindahkan wilayah kerja surveyor (${iData.Nama})!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        http
          .get(
            `${URLSVC}/Webs/ApprovePerubahanWilkerSurveyor?IdMemberUpdate=${iData.IdMemberUpdate}`
          )
          .then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda berhasil terima surveyor (${iData.Nama})`,
                "success"
              ).then(function () {
                dispatch({
                  type: "MODAL_PERUBAHAN_WILKER_SURVEYOR",
                  payload: false,
                });
                dispatch(setDataPerubahanWilkerSurveyors());
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};

export const apiRejectPerubahanWilkerSurveyor = (iData) => {
  loadingBar(true);
  if (iData.Catatan === "") {
    Swal.fire("Gagal", `Catatan harus diisi`, "error");
    return;
  }
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan tolak perpanjang lisensi surveyor (${iData.Nama})!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        http
          .get(
            `${URLSVC}/Webs/RejectPerubahanWilkerSurveyor?IdMemberUpdate=${iData.IdMemberUpdate}&Catatan=${iData.Catatan}`
          )
          .then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda berhasil tolak perubahan wilayah kerja surveyor (${iData.Nama})`,
                "success"
              ).then(function () {
                dispatch({
                  type: "MODAL_PERUBAHAN_WILKER_SURVEYOR",
                  payload: false,
                });
                dispatch(setDataPerubahanWilkerSurveyors());
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};
