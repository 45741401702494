import { FUNCDateToString, http } from "src/config";
import { URLSVC } from "src/config/helper/urlService";
import Swal from "sweetalert2";
import { loadingBar } from "src/config/redux/action";
import axios from "axios";

export const setFormPerpanjangMemberSb = (formType, formValue) => {
  return { type: "FORM_PERPANJANG_MEMBER_SB", formType, formValue };
};

export const setDataPerpanjangMemberSbs = () => {
  return (dispatch) => {
    loadingBar(true);
    http.get(`${URLSVC}/Webs/GetPerpanjangMemberSurveyors`).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        dispatch({
          type: "DATA_PERPANJANG_MEMBER_SBS",
          payload: data.Data,
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const setDataPerpanjangMemberSb = (id, setValue = null) => {
  return (dispatch) => {
    loadingBar(true);
    http
      .get(`${URLSVC}/Webs/GetPerpanjangMemberSurveyor?IdMemberUpdate=${id}`)
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch(setFormPerpanjangMemberSb("IdMemberUpdate", id));
          dispatch(setFormPerpanjangMemberSb("Nama", data.Data.Nama));
          dispatch(setFormPerpanjangMemberSb("NoAnggota", data.Data.NoAnggota));
          dispatch(setFormPerpanjangMemberSb("Durasi", data.Data.Durasi));
          dispatch(
            setFormPerpanjangMemberSb("JumlahBayar", data.Data.JumlahBayar)
          );
          dispatch(
            setFormPerpanjangMemberSb(
              "TanggalPengajuan",
              data.Data.TanggalPengajuan
            )
          );
          dispatch(
            setFormPerpanjangMemberSb("TanggalRespon", data.Data.TanggalRespon)
          );
          dispatch(setFormPerpanjangMemberSb("Catatan", data.Data.Catatan));
          dispatch(setFormPerpanjangMemberSb("Status", data.Data.Status));
          dispatch(setFormPerpanjangMemberSb("FileDownloads", data.Data.Files));
          dispatch({
            type: "DATA_PERPANJANG_MEMBER_SB",
            payload: data.Data,
          });
          dispatch({
            type: "MODAL_PERPANJANG_MEMBER_SB",
            payload: true,
          });
          if (setValue !== null) {
            setValue("IdMemberUpdate", data.Data.IdMemberUpdate);
            setValue("Nama", data.Data.Nama);
          }
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const apiPerpanjangMemberSbWeb = (iData) => {
  return (dispatch) => {
    console.log("iData : ", iData);
    loadingBar(true);
    var fd = new FormData();

    fd.append("NoRegSb", iData.NoRegSb);
    fd.append("Durasi", iData.Durasi.value);
    fd.append("JumlahBayar", iData.JumlahBayar);
    if (iData.Files.length !== 0) {
      iData.Files.map((v, i) => {
        if (typeof v.IdFile !== "undefined") {
          fd.append(`FileBerkas[${i}].IdFile`, v.IdFile);
          fd.append(`FileBerkas[${i}].FileUpload`, v.FileUpload);
        }
      });
    } else {
      loadingBar(false);
      Swal.fire("Gagal", `File berkas wajib di upload`, "error");
      return;
    }

    axios.post(`${URLSVC}/Webs/PerpanjangMemberSurveyorWeb`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Pengajuan untuk perpanjang masa aktif kartu anggota berhasil dilakukan, silahkan tunggu konfirmasi dari operator!`,
          "success"
        ).then(function () {
          window.location.reload();
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const apiPerpanjangMemberSb = (iData, reset) => {
  return (dispatch) => {
    loadingBar(true);
    var fd = new FormData();

    fd.append("Durasi", iData.Durasi.value);
    fd.append("JumlahBayar", iData.JumlahBayar);
    if (iData.Files.length !== 0) {
      iData.Files.map((v, i) => {
        if (typeof v.IdFile !== "undefined") {
          fd.append(`FileBerkas[${i}].IdFile`, v.IdFile);
          fd.append(`FileBerkas[${i}].FileUpload`, v.FileUpload);
        }
      });
    } else {
      loadingBar(false);
      Swal.fire("Gagal", `File berkas wajib di upload`, "error");
      return;
    }

    http.post(`${URLSVC}/Webs/PerpanjangMemberSurveyor`, fd).then((res) => {
      loadingBar(false);
      let data = res.data;
      if (data.IsSuccess) {
        Swal.fire(
          "Berhasil",
          `Pengajuan untuk perpanjang masa aktif kartu anggota berhasil dilakukan, silahkan tunggu konfirmasi dari operator!`,
          "success"
        ).then(function () {
          dispatch(setDataPerpanjangMemberSbs());
        });

        //reset value
        reset({ Durasi: "" });
        reset({ JumlahBayar: "" });
        iData.Files.map((v, i) => {
          var idFile = document.getElementById(`file${i}`);
          idFile.value = idFile.defaultValue;
        });
      } else {
        Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
      }
    });
  };
};

export const apiBatalPerpanjangMemberSb = (id) => {
  return (dispatch) => {
    loadingBar(true);
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan membatalkannya!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        http
          .get(
            `${URLSVC}/Webs/BatalPerpanjangMemberSurveyor?IdMemberUpdate=${id}`
          )
          .then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda telah membatalkan perpanjang kartu anggota`,
                "success"
              ).then(function () {
                dispatch(setDataPerpanjangMemberSbs());
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};

export const apiApprovePerpanjangMemberSb = (iData) => {
  return (dispatch) => {
    loadingBar(true);
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan perpanjang masa aktif anggota surveyor (${iData.Nama})!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        http
          .get(
            `${URLSVC}/Webs/ApprovePerpanjangMemberSurveyor?IdMemberUpdate=${iData.IdMemberUpdate}`
          )
          .then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda berhasil perpanjang masa aktif anggota surveyor (${iData.Nama})`,
                "success"
              ).then(function () {
                dispatch({
                  type: "MODAL_PERPANJANG_MEMBER_SB",
                  payload: false,
                });
                dispatch(setDataPerpanjangMemberSbs());
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};

export const apiRejectPerpanjangMemberSb = (iData) => {
  loadingBar(true);
  if (iData.Catatan === "") {
    Swal.fire("Gagal", `Catatan harus diisi`, "error");
    return;
  }
  return (dispatch) => {
    Swal.fire({
      title: "Apakah anda yakin?",
      text: `Anda akan tolak perpanjang masa aktif anggota surveyor (${iData.Nama})!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, saya yakin!",
    }).then((result) => {
      if (result.isConfirmed) {
        loadingBar(true);
        http
          .get(
            `${URLSVC}/Webs/RejectPerpanjangMemberSurveyor?IdMemberUpdate=${iData.IdMemberUpdate}&Catatan=${iData.Catatan}`
          )
          .then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
              Swal.fire(
                "Berhasil",
                `Anda berhasil tolak perpanjang masa aktif anggota surveyor (${iData.Nama})`,
                "success"
              ).then(function () {
                dispatch({
                  type: "MODAL_PERPANJANG_MEMBER_SB",
                  payload: false,
                });
                dispatch(setDataPerpanjangMemberSbs());
              });
            } else {
              Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
            }
          });
      }
    });
  };
};
