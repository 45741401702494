import { http } from 'src/config';
import { URLSVC } from 'src/config/helper/urlService';
import Swal from 'sweetalert2'
import { loadingBar } from '../globalAction';

export const setDataProvinsis = () => {
    loadingBar(true);
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetProvinsis`).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                dispatch({ type: 'DATA_PROVINSIS', payload: data.Data });
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}
export const setDataProvinsi = (idProvinsi) => {
    loadingBar(true);
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetProvinsi?IdProvinsi=${idProvinsi}`).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                dispatch(setFormProvinsi('Kode', data.Data.Kode));
                dispatch(setFormProvinsi('Nama', data.Data.Nama));
                dispatch(setFormProvinsi('EmailKorwil', data.Data.EmailKorwil));
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}

export const setFormProvinsi = (formType, formValue) => {
    return {type: 'FORM_PROVINSI', formType, formValue}
}

export const apiAddProvinsi = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('Kode', iData.Kode);
        fd.append('Nama', iData.Nama);
        fd.append('Email', iData.Email);

        http.post(`${URLSVC}/Webs/AddProvinsi`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                Swal.fire('Berhasil', `Anda telah berhasil menambah data`, 'success').then(function() {
                    dispatch({ type: 'MODAL_PROVINSI', payload: false });
                    dispatch(setDataProvinsis());
                });
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}

export const apiEditProvinsi = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('IdProvinsi', iData.Kode);
        fd.append('Kode', iData.Kode);
        fd.append('Nama', iData.Nama);

        http.post(`${URLSVC}/Webs/EditProvinsi`, fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                Swal.fire('Berhasil', `Anda telah berhasil memperbarui data`, 'success').then(function() {
                    dispatch({ type: 'MODAL_PROVINSI', payload: false });
                    dispatch(setDataProvinsis());
                });
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}

export const apiDeleteProvinsi = (idProvinsi, nama) => {
    return (dispatch) => {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: `Anda akan hapus provinsi (${nama})!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, saya yakin!'
        }).then((result) => {
            if (result.isConfirmed) {
                loadingBar(true);
                http.post(`${URLSVC}/Webs/DeleteProvinsi?IdProvinsi=${idProvinsi}`).then((res) => {
                    loadingBar(false);
                    let data = res.data;
                    if (data.IsSuccess) {
                        Swal.fire('Berhasil', `Anda berhasil hapus provinsi (${nama})`, 'success').then(function() {
                            dispatch(setDataProvinsi());
                        });
                    } else {
                        Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
                    }
                })
            }
        })
    }
}