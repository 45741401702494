import {
  CCallout,
  CCol,
  CModal,
  CModalBody,
  CModalHeader,
  CRow,
} from "@coreui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FUNCIndoDate } from "src/config";

const PopupPelayanan = () => {
  const { modalPelayananPreviewBerkas, dataPelayananPreviewBerkas } =
    useSelector((state) => state.globalReducer);
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch({
      type: "MODAL_PELAYANAN_PREVIEW_BERKAS",
      payload: false,
    });
  };
  console.log("dataPelayananPreviewBerkas", dataPelayananPreviewBerkas);
  return (
    <CModal size="xl" show={modalPelayananPreviewBerkas} onClose={closeModal}>
      <CModalHeader closeButton>Lihat Berkas</CModalHeader>
      <CModalBody>
        {dataPelayananPreviewBerkas && (
          <CRow>
            <CCol md="6">
              <div className="table table-responsive">
                <table style={{ width: "100%" }}>
                  <tr>
                    <td>No. Permohonan &nbsp;</td>
                    <td>
                      : <code>#{dataPelayananPreviewBerkas.NoPermohonan}</code>
                    </td>
                  </tr>
                  <tr>
                    <td>Layanan</td>
                    <td>
                      : <b>{dataPelayananPreviewBerkas.JenisPermohonan.Nama}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Wilayah Kerja</td>
                    <td>
                      : <b>{dataPelayananPreviewBerkas.Wilker.Nama}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Kabupaten/Kota</td>
                    <td>
                      : <b>{dataPelayananPreviewBerkas.Kabupaten.Nama}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Kecamatan</td>
                    <td>
                      : <b>{dataPelayananPreviewBerkas.Kecamatan.Nama}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Desa</td>
                    <td>
                      : <b>{dataPelayananPreviewBerkas.Desa.Nama}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Latitude, Longitude</td>
                    <td>
                      :
                      <b>
                        {" "}
                        {dataPelayananPreviewBerkas.Latitude},{" "}
                        {dataPelayananPreviewBerkas.Longitude}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>KJSB</td>
                    <td>
                      : <b>{dataPelayananPreviewBerkas.Kjsb.Nama}</b>
                    </td>
                  </tr>
                </table>
              </div>
            </CCol>
            <CCol md="6">
              <div className="table table-responsive">
                <table style={{ width: "100%" }}>
                  <tr>
                    <td>Nama Pemohon</td>
                    <td>
                      : <b>{dataPelayananPreviewBerkas.Nama}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>No. KTP Pemohon</td>
                    <td>
                      : <b>{dataPelayananPreviewBerkas.NoKtp}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Alamat Pemohon</td>
                    <td>
                      : <b>{dataPelayananPreviewBerkas.Alamat}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>No. HP Pemohon</td>
                    <td>
                      : <b>{dataPelayananPreviewBerkas.NoHp}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Email Pemohon</td>
                    <td>
                      : <b>{dataPelayananPreviewBerkas.Email}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Luas Tanah</td>
                    <td>
                      :{" "}
                      <b>
                        {dataPelayananPreviewBerkas.LuasTanah.toLocaleString(
                          "id-ID"
                        )}{" "}
                        M2
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>Alamat Bidang Tanah</td>
                    <td>
                      : <b>{dataPelayananPreviewBerkas.AlamatBidangTanah}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Jarak Lokasi Bidang Tanah</td>
                    <td>
                      :
                      <b>
                        {" "}
                        {dataPelayananPreviewBerkas.JarakLokasiBidangTanah.toLocaleString(
                          "id-ID"
                        )}{" "}
                        KM
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td>Pemohon Diwakilkan</td>
                    <td>
                      :
                      <b>
                        {" "}
                        {dataPelayananPreviewBerkas.IsDiwakilkan
                          ? "Ya"
                          : "Tidak"}
                      </b>
                    </td>
                  </tr>
                </table>
              </div>
            </CCol>
            <CCol md="8">
              <hr />
              <div className="mx-3">
                <p>Lokasi Bidang Tanah</p>
                {dataPelayananPreviewBerkas.Latitude != null && (
                  <center>
                    <iframe
                      src={`https://maps.google.com/maps?q=${dataPelayananPreviewBerkas.Latitude}, ${dataPelayananPreviewBerkas.Longitude}&z=15&output=embed`}
                      height="600"
                      frameborder="0"
                      title="Lokasi"
                      style={{ border: 0, width: "100%" }}
                    ></iframe>
                  </center>
                )}
              </div>
            </CCol>
            <CCol md="4">
              <hr />
              <p>Status Perjalanan Berkas</p>
              {dataPelayananPreviewBerkas.Statuss.map((item, index) => {
                return (
                  <CCallout color="primary">
                    <small>
                      {FUNCIndoDate(item.Tanggal)}, {item.Jam}
                    </small>{" "}
                    <br />
                    <b>{item.Status}</b> <br />
                    Catatan : {item.Catatan ?? "tidak ada"}
                  </CCallout>
                );
              })}
            </CCol>
          </CRow>
        )}
      </CModalBody>
    </CModal>
  );
};

export default PopupPelayanan;
