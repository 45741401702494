import React from "react";
const Login = React.lazy(() => import("./Login"));
const NotFound = React.lazy(() => import("./NotFound"));
const DaftarSb = React.lazy(() => import("./DaftarSb"));
const DaftarKjsb = React.lazy(() => import("./DaftarKjsb"));
const DaftarPemohon = React.lazy(() => import("./DaftarPemohon"));
const UploadBayarSb = React.lazy(() => import("./UploadBayarSb"));
const UploadBayarKjsb = React.lazy(() => import("./UploadBayarKjsb"));
const PerpanjangSb = React.lazy(() => import("./PerpanjangSb"));
const PerpanjangKjsb = React.lazy(() => import("./PerpanjangKjsb"));

export {
  Login,
  NotFound,
  DaftarSb,
  DaftarKjsb,
  UploadBayarSb,
  UploadBayarKjsb,
  DaftarPemohon,
  PerpanjangSb,
  PerpanjangKjsb,
};
