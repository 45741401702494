import { http } from 'src/config';
import { URLSVC, URL } from 'src/config/helper/urlService';
import Swal from 'sweetalert2'
import { loadingBar, setImgPreview } from './globalAction';


export const setDataUsers = () => {
    loadingBar(true);
    return (dispatch) => {
        http.get(`${URLSVC}/Webs/GetUsers`).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                dispatch({type: 'DATA_USERS', payload: data.Data})
            } else {
                if(data.ReturnMessage != "data tidak ada") 
                    Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}

export const setDataUser = (idUser) => {
    return (dispatch) => {
        loadingBar(true);
        dispatch({type: 'RESET_FORM_ADD_USER'})
        http.get(`${URLSVC}/Webs/GetUser?IdUser=${idUser}`).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                dispatch({ type: 'DATA_USER', payload: data.Data })

                dispatch(setFormUser('IdUser', data.Data.IdUser))
                dispatch(setFormUser('Username', data.Data.Username))
                dispatch(setFormUser('Password', data.Data.Password))
                dispatch(setFormUser('Email', data.Data.Email))
                dispatch(setFormUser('FirstName', data.Data.FirstName))
                dispatch(setFormUser('MiddleName', data.Data.MiddleName))
                dispatch(setFormUser('LastName', data.Data.LastName))
                dispatch(setFormUser('Address', data.Data.Address))
                dispatch(setFormUser('PhoneNumber', data.Data.PhoneNumber))
                dispatch(setFormUser('MobileNumber', data.Data.MobileNumber))

                dispatch(setImgPreview('FILE_IMAGE_USER_PREVIEW', `${URL}/file/survey/${data.Data.FileImage}?${new Date().getTime()}`));

                var roles = [];
                data.Data.Roles.map((v, i) => {
                    roles.push({
                        value: v.IdRole,
                        label: v.RoleName,
                    });
                });
                
                dispatch(setFormUser('Roles', roles))

                dispatch({type: 'MODAL_USER', payload: true})
                
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}

export const setFormUser = (formType, formValue) => {
    return {type: 'ADD_USER', formType, formValue}
}

export const apiAddUser = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('IdUser', iData.IdUser);
        fd.append('Username', iData.Username);
        fd.append('Password', iData.Password);
        fd.append('Email', iData.Email);
        fd.append('FirstName', iData.FirstName);
        fd.append('MiddleName', iData.MiddleName);
        fd.append('LastName', iData.LastName);
        fd.append('Address', iData.Address);
        fd.append('PhoneNumber', iData.PhoneNumber);
        fd.append('MobileNumber', iData.MobileNumber);
        fd.append('FileImage', iData.FileImage);

        iData.Roles.map((v, i) => {
            fd.append(`Roles[${i}][IdRole]`, v.value);
            fd.append(`Roles[${i}][RoleName]`, v.label);
        })

        http.post(`${URLSVC}/Webs/AddUser`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                Swal.fire('Berhasil', `Anda telah berhasil menambah data`, 'success').then(function() {
                    dispatch({ type: 'MODAL_USER', payload: false })
                    dispatch(setDataUsers())
                });
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}

export const apiUpdateUser = (iData) => {
    return (dispatch) => {
        loadingBar(true);
        var fd = new FormData();

        fd.append('IdUser', iData.IdUser);
        fd.append('Username', iData.Username);
        fd.append('Password', iData.Password);
        fd.append('Email', iData.Email);
        fd.append('FirstName', iData.FirstName);
        fd.append('MiddleName', iData?.MiddleName || '');
        fd.append('LastName', iData?.LastName || '');
        fd.append('Address', iData.Address);
        fd.append('PhoneNumber', iData.PhoneNumber);
        fd.append('MobileNumber', iData.MobileNumber);

        fd.append('FileImage', iData.FileImage);

        iData.Roles.map((v, i) => {
            fd.append(`Roles[${i}][IdRole]`, v.value);
            fd.append(`Roles[${i}][RoleName]`, v.label);
        })
        
        http.post(`${URLSVC}/Webs/EditUser`,fd).then((res) => {
            loadingBar(false);
            let data = res.data;
            if (data.IsSuccess) {
                Swal.fire('Berhasil', `Anda telah berhasil memperbarui data username (${iData.FirstName})`, 'success').then(function() {
                    dispatch({ type: 'MODAL_USER', payload: false });
                    dispatch(setDataUsers());
                });
            } else {
                Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
            }
        })
    }
}

export const apiAktivasiUser = (setIdUser, username) => {
    return (dispatch) => {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: `Anda akan aktivasi username (${username})!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, saya yakin!'
        }).then((result) => {
            if (result.isConfirmed) {
                http.post(`${URLSVC}/Webs/SetUserActive?SetIdUser=${setIdUser}`).then((res) => {
                    let data = res.data;
                    if (data.IsSuccess) {
                        Swal.fire('Berhasil', `Anda berhasil aktivasi username (${username})`, 'success').then(function() {
                            dispatch(setDataUsers())
                        });
                    } else {
                        Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
                    }
                })
            }
        })
    }
}

export const apiNonAktivasiUser = (setIdUser, username) => {
    return (dispatch) => {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: `Anda akan non-aktivasi username (${username})!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, saya yakin!'
        }).then((result) => {
            if (result.isConfirmed) {
                http.post(`${URLSVC}/Webs/SetUserInActive?SetIdUser=${setIdUser}`).then((res) => {
                    let data = res.data;
                    if (data.IsSuccess) {
                        Swal.fire('Berhasil', `Anda berhasil non-aktivasi username (${username})`, 'success')
                        dispatch(setDataUsers())
                    } else {
                        Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
                    }
                })
            }
        })
    }
}