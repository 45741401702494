const initialState = {
  isLoading: false,
  dataSyaratMemberUpdate: false,
  dataPelayananPreviewBerkas: false,
  modalPelayananPreviewBerkas: false,
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, isLoading: action.payload };
    case "DATA_SYARAT_MEMBER_UPDATE":
      return { ...state, dataSyaratMemberUpdate: action.payload };
    case "DATA_PELAYANAN_PREVIEW_BERKAS":
      return { ...state, dataPelayananPreviewBerkas: action.payload };
    case "MODAL_PELAYANAN_PREVIEW_BERKAS":
      return { ...state, modalPelayananPreviewBerkas: action.payload };
    default:
      return state;
  }
};

export default globalReducer;
