import { http, URLSVC } from "src/config";
import Swal from "sweetalert2";

export const setImgPreview = (type, payload) => {
  return { type, payload };
};

export const loadingBar = (bool = true) => {
  if (bool) {
    Swal.fire({
      title: "Sedang Memuat",
      html: "silahkan tunggu sebentar...",
      showConfirmButton: false,
      onBeforeOpen() {
        Swal.showLoading();
      },
      onAfterClose() {
        Swal.hideLoading();
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });
  } else {
    Swal.close();
  }
};

export const setDataSyaratMemberUpdate = (id) => {
  return (dispatch) => {
    http
      .get(`${URLSVC}/Webs/GetSyaratMemberUpdate?IdMemberJenisUpdate=${id}`)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({
            type: "DATA_SYARAT_MEMBER_UPDATE",
            payload: data.Data,
          });
        } else {
          Swal.fire("Gagal", `${data.ReturnMessage}`, "error");
        }
      });
  };
};

export const setDataPermohonans = (type, status = "") => {
  loadingBar(true);
  return (dispatch) => {
    http
      .get(`${URLSVC}/Pelayanans/GetPermohonanAll?Status=${status}`)
      .then((res) => {
        loadingBar(false);
        let data = res.data;
        if (data.IsSuccess) {
          dispatch({ type, payload: data.Data });
        } else {
          // Swal.fire('Gagal', `${data.ReturnMessage}`, 'error');
        }
      });
  };
};
